@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  .small-toolbar {
    background-color: mat.get-color-from-palette($primary-palette, 900);
    color: mat.get-color-from-palette($primary-palette, 900-contrast);
    font-weight: bold;

    .mat-mdc-button:not(:disabled) {
      color: mat.get-color-from-palette($primary-palette, 900-contrast);
    }
  }

}

@mixin typography($theme) {}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
