@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  .fm-titlebar {
    a {
      color: mat.get-color-from-palette($primary-palette);
      text-decoration: none;
      &:hover {
        color: mat.get-color-from-palette($accent-palette);
      }
    }

    .mat-mdc-select-value,
    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
    .mat-mdc-select-arrow,
    .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
      color: mat.get-contrast-color-from-palette($primary-palette,900);
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
    .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-line-ripple::after {
      border-bottom-color: mat.get-contrast-color-from-palette($primary-palette,900);
    }

  }

}

@mixin typography($theme) {}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
