@use '@angular/material' as mat;

$mat-primary: (
  50 : #e4f2fe,
  100 : #BCDDFD, //official-color
  200 : #90cbf9,
  300 : #64b6f7,
  400 : #42a6f5,
  500 : #2196f3, //official-color
  600 : #1d8ef1,
  700 : #1883ef,
  800 : #1479ed,
  900 : #0571C7, //official-color
  A100 : #ffffff,
  A200 : #e1ecff,
  A400 : #aeccff,
  A700 : #95bcff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$mat-accent: (
  50 : #f8f8f8,   //official-color
  100 : #ededed,  //official-color
  200 : #d4d4d4,
  300 : #d2d2d2,  //official-color
  400 : #b6b6b6,
  500 : #a9a9a9,  //official-color
  600 : #a2a2a2,
  700 : #989898,
  800 : #8f8f8f,
  900 : #464646,  //official-color
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
  )
);

$mat-warn: (
  50 : #fce5e3,
  100 : #f7bfb8,
  200 : #f29489,
  300 : #ec6959,
  400 : #e84936,
  500 : #e42912, //official-color
  600 : #e12410,
  700 : #dd1f0d,
  800 : #d9190a,
  900 : #d10f05,
  A100 : #fffafa,
  A200 : #ffc8c7,
  A400 : #ff9794,
  A700 : #ff7e7b,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$app-primary: mat.define-palette($mat-primary);
$app-accent: mat.define-palette($mat-accent);
$app-warn: mat.define-palette($mat-warn);
$typography-config: mat.define-typography-config();
@include mat.typography-hierarchy($typography-config);


$app-theme: mat.define-light-theme((
  color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn),
      typography: $typography-config
  )
);

//Here we have some additional colors based on the Schwarz CD
:root {
  --success: #43B970;
  --error: #E42912;
  --2nd-accent: #FF9800;
  --red-primary: #E10915
}
