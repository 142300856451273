@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, primary);
  $accent-palette: map.get($color-config, accent);
  $warn-palette: map.get($color-config, warn);
  .warn {
    background-color: mat.get-color-from-palette($warn-palette, 500);
    color: mat.get-color-from-palette($warn-palette, 500-contrast);
  }
  .info {
    background-color: mat.get-color-from-palette($accent-palette, 500);
    color: mat.get-color-from-palette($warn-palette, 500-contrast);
  }
}

@mixin typography($theme) {
  $my-typography: mat.define-typography-config();
  .fm-banner {
    @include mat.typography-level($my-typography, 'subtitle-2');
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
