@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $warn-palette: map.get($color-config, 'warn');

  .mat-snack-bar-container {
    color: mat.get-color-from-palette($warn-palette, '500-contrast');
  }

   .mat-mdc-snack-bar-container.panelClass-error .mdc-snackbar__surface {
      background-color: mat.get-color-from-palette($warn-palette);
   }

  .mat-mdc-snack-bar-container.panelClass-success .mdc-snackbar__surface {
    background-color: forestgreen;
  }
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
