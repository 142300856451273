@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);
  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  .form-group {
    border: 1px solid mat.get-color-from-palette($primary-palette, 100);

    .form-group-header {
      background-color: mat.get-color-from-palette($primary-palette, 100);
      color: mat.get-color-from-palette($primary-palette);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  .form-group-header {
    font-weight: bold;
  }

  .select-all > mat-checkbox > .mdc-form-field {
    @include mat.typography-level($typography-config, 'subtitle-1')
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
