@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

    .object-details-item {
      .caption {
        color: mat.get-color-from-palette($primary-palette);
      }
      border-bottom: 1px solid mat.get-color-from-palette($primary-palette, 50);
    }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .object-details-item {
    .caption {
      @include mat.typography-level($typography-config, 'caption');
      font-weight: bold;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
