@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  table.fm-row-hover, .mat-mdc-table.fm-row-hover {
    .mdc-data-table__row:hover {
      cursor: pointer;
      background: mat.get-color-from-palette($primary-palette, 50);
    }
  }

  .mat-mdc-table tr.mat-mdc-row:nth-child(2n+1)  {
    background: mat.get-color-from-palette($accent-palette, 50);
  }

  .fm-collapsable-rows {
    tr.mat-mdc-row:nth-child(2n+1) {
      background: none;
    }
    tr.mat-mdc-row:nth-child(4n+1) {
      background: mat.get-color-from-palette($accent-palette, 50);
    }
  }

  input.fm-small-input {
    border: 1px solid mat.get-color-from-palette($primary-palette, 50);

    &:focus {
      border: 1px solid mat.get-color-from-palette($primary-palette);
    }
  }
}

@mixin typography($theme) {}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
