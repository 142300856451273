@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  // this is a default link with the primary color! on hover it is the accent color
  a {
    color: mat.get-color-from-palette($primary-palette);
    text-decoration: none;
    &:hover {
      color: mat.get-color-from-palette($accent-palette);
    }
  }

  // this is a default background container, which can be used to place components in it.


  .fm-background-container {
    margin-bottom: 16px;
    background-color: white;
    padding: 16px;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  .fm-bold-list-item {
    .mdc-list-item__primary-text {
      font-weight: bold !important;
    }
  }
}

@mixin typography($theme) {}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
