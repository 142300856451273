@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .mat-drawer-container {
    background-color: mat.get-color-from-palette($primary-palette, 900-contrast);

    .mat-drawer-inner-container .mat-list-base {
      padding-top: 0;
    }
    .mat-drawer {
      background: mat.get-color-from-palette($primary-palette, 50);
      .is-active {
        color: mat.get-color-from-palette($primary-palette);
      }
    }
  }
}

@mixin typography($theme) {}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
