@use '@angular/material' as mat;

@mixin flag-icon-lib($countries) {
  .flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .flag-icon {
    @extend .flag-icon-background;
    position: relative;
    display: inline-block;
    width: 24px;
    line-height: 18px;
    &:before {
      content: '\00a0';
    }
  }
  @each $country in $countries {
    .flag-icon-#{$country} {
      background-image: url('assets/flags/#{$country}.svg');
    }
  }
}
