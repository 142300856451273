@use '@angular/material' as mat;
@use 'variables' as var;
@use 'sass:map';

@mixin logisticsToolbar($theme) {
  $color-config: mat.get-color-config($theme);
  $accent-palette: map.get($color-config, 'accent');

  .small-toolbar {
    background-color: mat.get-color-from-palette($accent-palette, 50);
    color: mat.get-color-from-palette($accent-palette, 900);

    .mat-mdc-button:not(:disabled) {
      color: mat.get-color-from-palette($accent-palette, 900);
    }

    a {
      color: mat.get-color-from-palette($accent-palette, 900);
      &:hover {
        color: var(--red-primary);
      }
    }
  }
}

@mixin logisticsTitlebar($theme) {
  $color-config: mat.get-color-config($theme);
  $accent-palette: map.get($color-config, 'accent');

  .mat-toolbar.mat-primary {
    background-color: mat.get-color-from-palette($accent-palette, 50);
    color: mat.get-color-from-palette($accent-palette, 900);
    .content {
      color: var(--red-primary);
    }
  }
}

@mixin logisticsForms($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);
  // Get the primary color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');

  .form-group {
    border: 1px solid mat.get-color-from-palette($accent-palette, 100);

    .form-group-header {
      background-color: mat.get-color-from-palette($accent-palette, 100);
    }
  }
}

