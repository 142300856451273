@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $accent: map.get($color-config, 'primary');
  .document-count {
    color: mat.get-color-from-palette($accent, 500);
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  .document-count {
      @include mat.typography-level($typography-config, 'body-2');
  }

}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
