@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $accent: map.get($color-config, 'accent');
  .page {
    color: mat.get-color-from-palette($accent, 900);
  }
  .info-circle {
    background: rgba(255, 255, 255, 0.75);
    border: 10px solid #ED467A;
  }

}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  .info-circle {
    .circle-content {
      .value {
        @include mat.typography-level($typography-config, 'subtitle-1');
        font-weight: bold;
      }
      .caption {
        @include mat.typography-level($typography-config, 'body-1');
        font-weight: bold;
      }
    }

  }

}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
